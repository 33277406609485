import sort from "./images/sortovoy__prokat.webp"
import trub from "./images/trubniy__prokat.png"
import list from "./images/listovoy__prokat.png"
import nerj from "./images/nerjaveyushiyi__prokat.png"
import ferr from "./images/ferrosplavi.png"
import gruz from "./images/gruzopodemnoe__oborudovanie.jpg"

const items = [
    {
      id: 1,
      title: "Сортовой прокат",
      imgSrc: sort,
      description: "Заготовки различных сечений для изготовления конструкций и механизмов",
      path: "/sortovoiProkat"
    },
    {
      id: 2,
      title: "Трубный прокат",
      imgSrc: trub,
      description: "Трубы различных диаметров и толщин для строительства и производства трубопроводов",
      path: "/TrubniyProkat"
    },
    {
      id: 3,
      title: "Листовой прокат",
      imgSrc: list,
      description: "Листы различных толщин и размеров для изготовления разных деталей и конструкций",
      path: "/ListovoiProkat"
    },
    // {
    //   id: 4,
    //   title: "Нержавеющий прокат",
    //   imgSrc: nerj,
    //   description: "Заготовки, выполненные из нержавеющей стали, для пищевой и химической промышленности",
    //   path: "/NerjaveLushiyProkat"
    //   // 
    //   // Поменять потом путь, когда будет инфа что добавить
    //   // 
    // },
    {
      id: 5,
      title: "Ферросплавы",
      imgSrc: ferr,
      description: "Произведем поставку любых видов ферросплавов точно в срок напрямую от производителя",
      path: "/Ferrosplavi"
    },
    {
      id: 6,
      title: "Грузоподъемное оборудование",
      imgSrc: gruz,
      description: "Доставим грузоподъемное оборудование от производителя",
      path: "/LiftingEquipment"
    }
  ];
  
  export default items;