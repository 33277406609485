import React from "react";
import "./pages.css";
import Breadcrumbs from "../componentBreadCrumps/Breadcrumbs";
import "bootstrap/dist/css/bootstrap.min.css";
import ConditionComp from "../componentCondition/Condition";
import sortPrice from "../../price/testoviypricelist.xlsx";
import sortprokat from "../../images/rolledMetalSort/sortprokat.jpg";
import BannerInfo from "../componentBannerInfoForPages/BannerInfo";
import SalesBlock from "../componentSales/salesBlock";
import InfoBlock from "../componentInfoBlock/InfoBlock";
import YandexMap from "../componentYandexMap/yandexMap";
import { Helmet } from "react-helmet";
import image1 from "../../images/Ferrosplavi/Ferrosplavi1.webp"
import image2 from "../../images/Ferrosplavi/Ferrosplavi2.webp"
const items = [
  { name: "Ферросилиций" },
  { name: "Ферросиликомарганец" },
  { name: "Ферротитан" },
  { name: "Ферромарганец" },
  { name: "Ферромолибден" },
  { name: "Феррованадий" },
  { name: "Феррохром" },
  { name: "Ферровольфрам" },
  { name: "Хром металлический" },
];

const SortTitle = "Оптовая продажа ферросплавной продукции";
const SortText =
  "Не знаете, где недорого купить ферросплавы оптом? Компания «ММГК» предлагает широкий ассортимент ферросплавов высокого качества по доступным ценам. Мы являемся надежным поставщиком ферросплавной продукции уже много лет и готовы предложить нашим клиентам оптимальные условия сотрудничества.";
const SortText2 =
  "Мы осуществляем продажу ферросплавов различных видов и фракций. Вся продукция сертифицирована и соответствует высоким стандартам качества.";

function Ferrosplavi() {
  return (
    <div className="container-fluid p-0">
      <Helmet>
        <title>Ферросплавы</title>
      </Helmet>
      <BannerInfo img="backgroundImages/ferrosplaviBG.webp" title="Ферросплавы"></BannerInfo>
      <div className="container-fluid p-0 pb-5 bg-light bg-gradient">
        <div className="container">
          <Breadcrumbs />
          <h2>Ферросплавы</h2>
          <div className="row">
            {items.map((item, index) => (
              <div className="col-md-3 col-6 my-2 user-select-none" key={index}>
                <div
                  className="card bg-secondary text-white"
                  style={{ height: "100px" }}
                >
                  <div className="card-body d-flex align-items-center justify-content-center">
                    <h5 className="card-title text-center text-break">
                      {item.name}
                    </h5>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="text-center">
        {/* <a href={sortPrice} download="ТестПрайсЛистСпиздилСКакоготоСайта.xlsx">
          <button className="btn btn-warning">Скачать прайс-лист</button>
        </a> */}
      </div>
      <ConditionComp imgSrc={image1}></ConditionComp>
      <SalesBlock></SalesBlock>
      <InfoBlock
        title={SortTitle}
        img={image2}
        text={SortText}
        text2={SortText2}
      ></InfoBlock>
      <YandexMap></YandexMap>
    </div>
  );
}

export default Ferrosplavi;
