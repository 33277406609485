import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../../images/logos/logo_new_negate.webp';
import './Navbar.css';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);

  const menuItems = [
    { id: 1, path: '/', label: 'Главная' },
    { id: 2, path: '/about', label: 'О компании' },
    {
      id: 3,
      label: 'Продукция',
      subItems: [
        { id: '3-1', path: '/sortovoiProkat', label: 'Сортовой прокат' },
        { id: '3-2', path: '/TrubniyProkat', label: 'Трубный прокат' },
        { id: '3-3', path: '/ListovoiProkat', label: 'Листовой прокат' },
        // { id: '3-4', path: '/NerjaveLushiyProkat', label: 'Нержавеющий прокат' },
        { id: '3-5', path: '/Ferrosplavi', label: 'Ферросплавы' },
        { id: '3-6', path: '/LiftingEquipment', label: 'Грузоподъемное оборудование' },
        { id: '3-7', path: '/AllProducts', label: 'Вся продукция' },
      ],
    },
    { id: 4, path: '/contacts', label: 'Контакты' },
  ];

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      setHasScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.navbar-container') && isOpen) {
        closeMenu();
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <>
      <nav className={`navbar fixed-top ${hasScrolled ? 'scrolled' : ''}`}>
        <div className="navbar-container container">
          <NavLink to="/" className="navbar-logo">
            <img src={logo} alt="Название Компании" height="30" />
          </NavLink>
          <button className="navbar-toggler" onClick={toggleMenu}>
            ≡
          </button>
          <ul className={`navbar-menu ${isOpen ? 'active' : ''}`}>
            {menuItems.map((item) =>
              item.subItems ? (
                <li key={item.id} className="navbar-item dropdown navbar-link">
                  <span>{item.label}</span>
                  <ul className="dropdown-menu"  id='mobile__menu'>
                    {item.subItems.map((subItem) => (
                      <li key={subItem.id}>
                        <NavLink
                          to={subItem.path}
                          className="dropdown-item text-truncate"
                          onClick={closeMenu}>
                          {subItem.label}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </li>
              ) : (
                <li key={item.id} className="navbar-item">
                  <NavLink
                    to={item.path}
                    className="navbar-link"
                    onClick={closeMenu}>
                    {item.label}
                  </NavLink>
                </li>
              ),
            )}
          </ul>
        </div>
      </nav>
      <div className={`overlay__canvas__menu ${isOpen ? 'active' : ''}`} onClick={closeMenu}></div>
    </>
  );
}

export default Navbar;
